.videoCard {
    position: relative;
}

.videoCard :global .ant-card-body {
    padding: 18px;
}

.videoCard :global .ant-card-meta-title {
    margin: 0 !important;
}

.videoCard :global .ant-card-head {
    position: absolute;
    width: 100%;
    border: none;
    padding: 0px 12px !important;
    top: 0;
    width: 0;
}

.videoCard :global .ant-card-actions {
    height: 32px;
    position: relative;
}

.videoCard :global .ant-card-meta-description {
    font-size: 12px;
}

.videoCard :global .ant-card-actions :global .ant-btn {
    position: absolute;
    top: -12px;
    left: 0;
    border-radius: 0 0 8px 8px;
}