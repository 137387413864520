.main {
  height: 100%;
  width: 100%;
  position: absolute;
  min-height: 98vh;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  margin-top: 88px;
}

.logoWrapper {
  position: relative;
}

.logoWrapper :global .ant-image > img {
  border-radius: 100px;
  height: 150px;
  width: 150px;
}

.logoWrapper :global .ant-upload {
  padding: 0 !important;
  border: none;
  background-color: transparent;
}

.logoWrapper :global .ant-float-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.loaderWrapper {
  display: grid;
  place-content: center;
  width: 100%;
  padding-top: 22px;
}

.profileWrapper {
  height: 100%;
  width: 100%;
  background-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.profileWrapper :global .ant-card {
  max-width: 1000px;
  min-width: 60%;
  margin-top: 112px;
}

.arenasDrawerContent {
  min-height: 70vh;
}

.arenaItem {
  max-width: 500px;
  min-width: 270px;
  background-color: var(--softGray);
  padding-left: 12px;
  cursor: pointer;
  border-radius: 5px;
}

.profilePicWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.banner {
  margin-top: 88px;
  background-color: var(--softGray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/public/futlance_goal.svg);
  background-repeat: no-repeat;
  background-position: right 5%;
  background-size: 20em;
  padding: 48px 12px;
}

.banner :global .title {
  font-size: 26px;
  margin: 0;
}

.banner :global .subtitle {
  font-size: 18px;
  margin: 0;
  text-align: center;
}

.plansContainer {
  display: flex;
  justify-content: space-between;
  width: 95%;
  max-width: 1200px;
  margin: auto;
  padding: 24px 0;
}

/* Center plans when no user plans exist */
.centered {
  justify-content: center;
}

.plansSection {
  flex: 1.3; /* Takes 1.3x the space compared to myPlansSection */
  padding: 0 12px;
}

.myPlansSection {
  flex: 0.7; /* Takes less space */
  padding: 0 12px;
}

.plansWrapper,
.myPlansWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: center;
}

@media (max-width: 900px) {
  .plansContainer {
    flex-direction: column;
    align-items: center;
  }

  .plansSection,
  .myPlansSection {
    width: 100%;
    padding: 0;
  }
}

.plansTitleWrapper {
  width: 95%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 22px;
  padding: 22px 0;
}

.plansTitleWrapper :global .plansTitle {
  font-size: 26px;
  margin: 0;
}

.myPlansTitleWrapper {
  width: 95%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 22px;
  padding: 22px 0;
}

.myPlansTitleWrapper :global .myPlansTitle {
  font-size: 26px;
  margin: 0;
}

@media (max-width: 900px) {
  .filters {
    margin-bottom: 12px;
  }

  .profileWrapper :global .ant-card {
    max-width: 1000px;
    min-width: 90%;
    margin-top: 112px;
  }
}
