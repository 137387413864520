.main {
  height: 100%;
  width: 100%;
  position: absolute;
  min-height: 98vh;
}

.videoList {
  position: relative;
  padding: 22px;
  width: 100%;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  margin-top: 88px;
}

.alertWrapper {
  padding: 0 20%;
}

.alertWrapper :global .ant-alert {
  cursor: pointer;
}

.alertWrapper :global .mobile-link {
  display: none;
}

.loaderWrapper {
  display: grid;
  place-content: center;
  width: 100%;
  padding-top: 22px;
}

/* .card :global .ant-form-item-row {
    text-align: left !important;
} */

@media (max-width: 1500px) {
  .alertWrapper {
    padding: 0 12%;
  }
}

@media (max-width: 900px) {
  .alertWrapper {
    padding: 0 22px;
  }

  .alertWrapper :global .mobile-link {
    display: inline;
  }

  .alertWrapper :global .ant-btn {
    display: none;
  }

  .filters {
    margin-bottom: 12px;
    display: grid;
  }
}
