.planCard :global .ant-card-head {
  border: none;
  text-align: center;
  font-size: 22px;
}

.planCard :global .ant-card-actions {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  width: 100%;
  border: none;
  padding: 0 22px;
}

.myPlanCard {
  background-color: #00993f1f;
}

.myPlanCard :global .ant-card-head {
  border: none;
  text-align: center;
  font-size: 22px;
}

.myPlanCard :global .ant-card-actions {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  width: 100%;
  border: none;
  padding: 0 22px;
  background-color: #00993f00;
}

.priceWrapper {
  display: flex;
  background-color: var(--gray);
  margin: -22px 22px 0px 22px;
  padding: 8px 12px 22px 12px;
  border-radius: 22px;
  justify-content: center;
  align-items: end;
}

.priceWrapper :global .price {
  font-size: 36px;
  font-weight: bolder;
  height: 46px;
}

.videosQuantityTag {
  background-color: var(--softGray);
  display: flex;
  margin: -12px 48px 0 48px;
  border-radius: 5px;
  justify-content: center;
  color: var(--primary);
  font-weight: bold;
}

.planTitle {
  font-weight: bold;
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
}

.planDescription {
  opacity: 0.7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  height: 110px;
  width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
