.main {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    height: 100%;
    width: 100%;
    position: absolute;
    min-height: 98vh;
}

.background {
    background-image: url(/public/background.png);
    background-size: cover;
    background-position: center;
}

.loginContainer {
    background-color: var(--gray);
    display: grid;
    place-items: center;
    padding: 22px;
}

.logo {
    width: 100%;
    height: 130px;
    object-fit: contain;
}

.card {
    width: 60%;
}

/* .card :global .ant-form-item-row {
    text-align: left !important;
} */

@media (max-width: 1500px) {
    .card {
        width: 90%;
    }
}

@media (max-width: 900px) {
    .main {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0;
    }

    .loginContainer {
        position: absolute;
        background: transparent;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .card {
        width: 100%;
    }

    .logo {
        height: 100px;
    }

}