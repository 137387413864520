.purchaseWrapper {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
}

.statusWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
}

.logo {
  height: 50px;
  border-radius: 6px;
  object-position: left;
  padding: 15px calc(20px + 8px);
  background-color: white;
}

.form {
  padding: 15px calc(20px + 8px);
}

@media (max-width: 900px) {
  .purchaseWrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}

.creditCardFields {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Espaçamento entre os campos */
}

.creditCardFields .ant-form-item {
  flex: 1 1 calc(50% - 8px); /* Para dividir em duas colunas */
  min-width: 180px; /* Evita que os campos fiquem muito pequenos */
}

.expiryFields {
  display: flex;
  gap: 8px;
}

.expiryFields .ant-form-item {
  flex: 1;
}
