.header {
    margin-bottom: 12px;
}

.form :global .ant-select {
    width: 183px;
}

.arenasDrawerContent {
    min-height: 70vh;
}

.arenaItem {
    max-width: 500px;
    min-width: 270px;
    background-color: var(--softGray);
    padding-left: 12px;
    cursor: pointer;
    border-radius: 5px;
}

@media (max-width: 1500px) {
    .form :global .ant-space-item {
        width: 100%;
    }

    .form :global .ant-select {
        width: 100%;
    }

    .arenasDrawerContent {
        min-height: 100vh;
    }
}