.content {
    margin: 24px 16px 0;
    display: flex;
    flex-direction: column;
}

.main {
    padding: 24px;
    min-height: 360px;
    height: 100%;
    overflow-y: auto;
    position: relative;
}