.header {
    margin-bottom: 12px;
}

.tabs {
    margin-bottom: -16px;
}

.tabs :global .ant-tabs-tab-active {
    padding: 8px 24px 6px 24px !important;
}

.tabs :global .ant-tabs-tab-active>.ant-tabs-tab-btn {
    color: var(--primary) !important;
}

.tabs :global .ant-tabs-tab:hover {
    color: var(--primaryDark) !important;
}