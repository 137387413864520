:root {
  --primary50: #00994080;
  --primary: #00993f;
  --primaryDark: #005326;
  --gray: #f5f5f5;
  --defaultDark: #001529;
  --softGray: #eeefe5;
}

* {
  font-family: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--gray);
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0eca5d;
}

.main {
  position: absolute;
  height: 100%;
  width: 100%;
  min-height: 95vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-radio-button-wrapper-checked {
  color: white !important;
}

.ant-radio-button-checked {
  background-color: var(--primary);
}

.hidden-button {
  display: none;
}

.ant-picker-panel-container .ant-picker-presets {
  min-height: 25rem !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:last-child {
  display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra>div {
  flex-wrap: wrap !important;
}

.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
  display: none !important;
}

.ant-picker-header {
  padding: 0 !important;
  width: 280px;
}

.ant-picker-header-next-btn,
.ant-picker-header-prev-btn {
  width: 50px;
  border: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-popover {
  z-index: 3;
}

@media only screen and (max-width: 767.99px) {
  .ant-layout {
    position: relative;
    overflow: hidden;
    min-width: 100vw;
    min-height: 100vh;
  }
}