.userHeader {
  width: fill-available;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 22px;
  padding: 22px calc(22px + 8px);
  background-color: white;
  top: 0;
  z-index: 3;
}

.userHeader :global .actions {
  display: flex;
  align-items: center;
  gap: 22px;
}

.avatar {
  background: var(--primary);
}

.logo {
  height: 44px;
  border-radius: 6px;
  object-position: left;
}

@media (max-width: 900px) {
  .myFilter {
    display: none;
  }
}
