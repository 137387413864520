.main {
    height: 100%;
    width: 100%;
    position: absolute;
    min-height: 98vh;
}

.filters {
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    margin-top: 88px;
}

.logoWrapper {
    position: relative;

}

.logoWrapper :global .ant-image>img {
    border-radius: 100px;
    height: 150px;
    width: 150px;
}

.logoWrapper :global .ant-upload {
    padding: 0 !important;
    border: none;
    background-color: transparent;
}

.logoWrapper :global .ant-float-btn {
    position: absolute;
    bottom: 0;
    right: 0;
}

.loaderWrapper {
    display: grid;
    place-content: center;
    width: 100%;
    padding-top: 22px;
}

.profileWrapper {
    height: 100%;
    width: 100%;
    background-color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.profileWrapper :global .ant-card {
    max-width: 1000px;
    min-width: 60%;
    margin-top: 112px;
}

.arenasDrawerContent {
    min-height: 70vh;
}

.arenaItem {
    max-width: 500px;
    min-width: 270px;
    background-color: var(--softGray);
    padding-left: 12px;
    cursor: pointer;
    border-radius: 5px;
}

.profilePicWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 900px) {
    .filters {
        margin-bottom: 12px;
    }

    .profileWrapper :global .ant-card {
        max-width: 1000px;
        min-width: 90%;
        margin-top: 112px;
    }
}