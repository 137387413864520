.header {
    margin-bottom: 12px;
}

.form :global .ant-select {
    width: 183px;
}

@media (max-width: 1500px) {
    .form :global .ant-space-item {
        width: 100%;
    }

    .form :global .ant-select {
        width: 100%;
    }

}