.sumaryWrapper {
    padding: 4px 22px;
}

.sumaryWrapper :global .ant-typography {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
}

.videosWrapper {
    max-height: 300px;
    overflow-y: auto;
}



@media (max-width: 900px) {
    .videosWrapper {
        max-height: 150px;
    }

}