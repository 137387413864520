.wrapper {
  position: absolute;
  background-color: var(--defaultGray);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 1fr 60px;
  place-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoWrapper > div {
  position: absolute;
  width: calc(100% - 44px) !important;
  height: 100% !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--defaultDark);
  border-radius: 12px;
  background-color: black;
}

.downloadButton {
  background-color: var(--primary);
  color: var(--gray);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 20px;
  display: inline-block;
}

.downloadButton:hover {
  background-color: #e64a19; /* Slightly darker shade for hover effect */
}

.downloadButton:active {
  background-color: #d84315; /* Darker shade for active click effect */
}

.logo {
  width: 150px;
  height: 75px;
  object-fit: contain;
}

.footer {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: var(--defaultDark);
  justify-content: center;
}

.playerActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: min-content;
  align-items: center;
}

.playerActions :global .listWrapper {
  padding: 0 22px;
  display: flex;
  gap: 12px;
}

.playerActions :global .listTitle {
  font-size: 18px;
  margin: 0;
}

.playerActions :global .listSubtitle {
  font-size: 12px;
  margin: 0;
  font-weight: lighter;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  padding: 36px;
}

@media (max-width: 900px) {
  .wrapper {
    grid-template-rows: 130px 1fr 60px;
  }

  .playerActions {
    display: grid;
    grid-template-columns: repeat(2, 0.5fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .logo {
    height: 60px;
  }

  .logoWrapper {
    grid-area: 1 / 1 / 2 / 3;
    justify-self: center;
  }

  .playerActions :global #left_header {
    grid-area: 2 / 1 / 3 / 2;
  }

  .playerActions :global #right_header {
    grid-area: 2 / 2 / 3 / 3;
    justify-self: flex-end;
  }

  .playerActions :global .listTitle {
    font-size: 14px;
  }

  .playerActions :global .listSubtitle {
    font-size: 11px;
  }

  .playerActions :global .listWrapper {
    padding: 0 22px;
    justify-self: center;
  }
}
