.header {
    margin-bottom: 12px;
}

.form :global .ant-select {
    width: 183px;
}

.logoWrapper {
    position: relative;
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.logoWrapper :global .ant-image {
    background: black !important;
    width: 150px !important;
}

@media (max-width: 1500px) {
    .form :global .ant-space-item {
        width: 100%;
    }

    .form :global .ant-select {
        width: 100%;
    }

}