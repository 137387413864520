.cartItemWrapper {
  display: flex;
  gap: 12px;
  position: relative;
  width: 100%;
  align-items: center;
}

.cartItemPrice {
  position: absolute;
  top: 8px;
  left: 8px;
}

.cartItemTexts {
  width: 100%;
}

.cartItemTitle {
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cartItemDescription {
  opacity: 0.7;
  font-size: 12px;
}
