.wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 12px;
    align-items: baseline;
}

.header {
    margin-bottom: 12px;
}

.form :global .ant-select {
    width: 183px;
}

@media (max-width: 1500px) {
    .form :global .ant-space-item {
        width: 100%;
    }

    .form :global .ant-select {
        width: 100%;
    }

}

@media (max-width: 900px) {
    .wrapper {
        grid-template-columns: 1fr;
        gap: 22px;
    }
}