.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    padding: 0 22px;
}

.header :global .actions {
    display: flex;
    align-items: center;
    gap: 22px;
}

.avatar {
    background: var(--primary);
}