.filtersWrapper {
    background-color: var(--gray);
    border: 1px solid var(--softGray);
    padding: 12px 12px 12px 36px;
    display: flex;
    align-items: center;
    gap: 36px;
    border-radius: 50px;
    cursor: pointer;
    transition: all .5s;
}

.filtersWrapper:hover {
    background-color: var(--softGray);

}

.filterItem,
.filterItemMobile {
    text-align: left;
}

.filterItemMobile {
    display: none;
}

.filterItemTitle {
    font-weight: bold;
}

.filterItemText {
    opacity: 0.7;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.actions :global .sumary {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
}

.actions :global .sumary>strong {
    margin-left: 12px;
}

.actions :global .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}


@media (max-width: 1500px) {}

@media (max-width: 900px) {
    .filterItem {
        display: none;
    }

    .filterItemMobile {
        display: block;
    }

    .filtersWrapper {
        padding: 8px 8px 8px 22px;
    }
}